import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulIndustryPropTypes,
  allContentfulInsightTypePropTypes,
  locationPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import {
  alphabeticalSortObject,
  removeDuplicateContentfulByAttribute,
} from 'helpers/utils'
import BlogList from 'components/Initiatives/BlogList'
import Hero from 'components/UI/Hero'
import HeroInsights from 'components/Insights/Hero'

import Section from 'components/UI/Section'
import SEO from 'components/seo'
import useInitiativesFilterData from 'hooks/useInitiativesFilterData'

const Initiatives = ({pageContext, data, location}) => {
  const {nodeLocale} = pageContext
  const countryData = R.path(['contentfulCountry'], data)

  const {
    pageTitle,
    shortDescription,
    metaTitle,
    metaDescription: {metaDescription},
    filterByCountryLabel,
    filterByPillarLabel,
    noInitiativesMessages,
    noInitiativesCallToAction,
    noInitiativesLink,
  } = R.pathOr(null, ['allContentfulInitiatives', 'nodes', 0], data)

  const {filterSeeMoreLabel, filterSeeLessLabel} = R.pathOr(
    null,
    ['allContentfulInsights', 'nodes', 0],
    data,
  )

  const {
    allContentfulInitiative,
    allContentfulInitiativePillar,
    countryInitiativesIds,
    allTagsObjects,
    countryPillarsTagsObjects,
    countriesTagsObjects,
    allCountriesToggleTagObject,
  } = useInitiativesFilterData(data)

  const sortedCountriesTagsArray = alphabeticalSortObject(countriesTagsObjects)
  const filteredCountriesTagsArray = removeDuplicateContentfulByAttribute(
    sortedCountriesTagsArray,
    'name',
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <HeroInsights
        pageTitle={pageTitle}
        shortDescription={shortDescription.shortDescription}
        nodes={data.topInitiatives.nodes}
        titleVariant="h1"
      />
      {countryInitiativesIds ? (
        <BlogList
          countryInsightsIds={countryInitiativesIds}
          locale={nodeLocale}
          types={allContentfulInitiativePillar}
          typesTags={countryPillarsTagsObjects && countryPillarsTagsObjects}
          filteredCountriesTagsArray={filteredCountriesTagsArray}
          filterByTypeLabel={filterByPillarLabel}
          filterByCountryLabel={filterByCountryLabel}
          filterByCountryDefaultFilter={allCountriesToggleTagObject}
          filterSeeMoreLabel={filterSeeMoreLabel}
          filterSeeLessLabel={filterSeeLessLabel}
          pageContext={pageContext}
          countryData={countryData}
          location={location}
          allTagsObjects={allTagsObjects}
          hasSkip
        />
      ) : (
        <Section>
          <Hero
            description={noInitiativesMessages}
            ctaTitle={noInitiativesCallToAction}
            ctaLink={noInitiativesLink}
            hasCtaOnRight
          />
        </Section>
      )}
    </>
  )
}

Initiatives.propTypes = {
  data: shape({
    allContentfulIndustry: allContentfulIndustryPropTypes,
    allContentfulInsightType: allContentfulInsightTypePropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

Initiatives.defaultProps = {}

export const query = graphql`
  query getHeroInfoInitiatives($nodeLocale: String, $technicalName: String) {
    allContentfulInitiatives(
      filter: {
        node_locale: {eq: $nodeLocale}
        slug: {regex: "/^((?!-kls).)*$/"}
      }
    ) {
      nodes {
        metaTitle
        metaDescription {
          metaDescription
        }
        pageTitle
        filterByPillarLabel
        filterByCountryLabel
        filterByCountryDefaultFilter
        shortDescription {
          shortDescription
        }
        noInitiativesCallToAction
        noInitiativesLink
        noInitiativesMessages
      }
    }
    allContentfulInsights(
      filter: {
        node_locale: {eq: $nodeLocale}
        slug: {regex: "/^((?!-kls).)*$/"}
      }
    ) {
      nodes {
        filterSeeMoreLabel
        filterSeeLessLabel
      }
    }
    allContentfulInitiativeAllCountries: allContentfulInitiative(
      filter: {node_locale: {eq: $nodeLocale}}
      sort: {publicationDate: DESC}
    ) {
      nodes {
        contentful_id
        countriesOfDestination {
          name
          contentful_id
        }
        pillars {
          name
          contentful_id
        }
      }
    }
    allContentfulInitiative(
      filter: {
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          contentful_id
          id
          countriesOfDestination {
            name
            contentful_id
          }
          pillars {
            name
            contentful_id
          }
        }
      }
    }
    allCountriesInitiatives: allContentfulInitiative(
      filter: {node_locale: {eq: $nodeLocale}}
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          contentful_id
          id
          countriesOfDestination {
            name
            contentful_id
          }
        }
      }
    }
    allContentfulIndustry(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          insight {
            id
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    allContentfulInitiativePillar(
      filter: {node_locale: {eq: $nodeLocale}}
      sort: {initiative: {publicationDate: DESC}}
    ) {
      edges {
        node {
          initiative {
            publicationDate(formatString: "DD.MM.YY")
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            metaTitle {
              metaTitle
            }
            metaDescription {
              metaDescription
            }
            slug
          }
        }
      }
    }
    topInitiatives: allContentfulInitiative(
      filter: {
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
      limit: 2
    ) {
      nodes {
        publicationDate(formatString: "DD.MM.YY")
        node_locale
        title
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        metaTitle {
          metaTitle
        }
        metaDescription {
          metaDescription
        }
        slug
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      id
      ...countryDataFields
      name
      newsletterCampaignToken
      ...partnerFields
      initiative {
        countriesOfDestination {
          name
          contentful_id
        }
        pillars {
          name
          contentful_id
        }
      }
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default Initiatives
